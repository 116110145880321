@tailwind base;
@tailwind components;
@tailwind utilities;

.smooth-scroll {
  scroll-behavior: smooth;
}

* {
  font-family: "Fira Sans", sans-serif;
  user-select: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
